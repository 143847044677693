import React, { useEffect } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as Markdown from 'react-markdown'
import colors from '../styles/colors'
import Button from './atoms/Button'
import Usa from '../assets/icons/usa'
import Hardhat from '../assets/icons/hard-hat'
import Boxusd from '../assets/icons/boxusd'
import HeroRatingStars from './hero-rating-stars'
import Slider from 'react-slick'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { motion } from "framer-motion";
import { ArrowRight } from 'lucide-react'

const SliderContainer = styled.div`
  &:hover {
    .PrevNextArrowsContainer {
      opacity: 1 !important;
    }
  }
  .PrevNextArrowsContainer {
    opacity: 0.1;
    background-color: rgba(204, 204, 204, 0.4);
    &:hover {
      background-color: rgba(204, 204, 204, 0.9);
    }
  }
  &.server-rendered {
    .slick-track {
      left: 0% !important;
    }
  }
`
const PrevNextArrowsContainer = styled.div`
  position: absolute;
  z-index: 1;
  @media (max-width: 767px) {
    top: calc(50% - 15px);
    height: 30px;
    width: 30px;
    padding: 5px;
  }
  top: calc(50% - 25px);
  height: 50px;
  width: 50px;

  transition: 0.2s ease all;
  color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  &.carousel-icon-right {
    right: 18px;
  }
  &.carousel-icon-left {
    left: 18px;
  }
`


const NextArrow = (props) => {
  const { onClick } = props

  //auto scroll
  //setInterval(() => onClick(), 10000)

  return (
    <PrevNextArrowsContainer
      className="icon-container carousel-icon-left PrevNextArrowsContainer"
      onClick={onClick}
    >
      <ChevronLeft size={26} />
    </PrevNextArrowsContainer>
  )
}

const PrevArrow = (props) => {
  const { onClick } = props
  return (
    <PrevNextArrowsContainer
      className="icon-container carousel-icon-right PrevNextArrowsContainer"
      onClick={onClick}
    >
      <ChevronRight size={26} />
    </PrevNextArrowsContainer>
  )
}

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  cssEase: 'linear',
  className: 'disable-interaction-on-non-active relative',
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
}

const HeroContent = ({
  heroTitle,
  heroImage,
  description,
  primaryActionBtnLabel,
  primaryActionBtnLink,
  style,
  alt,
  preloadImage,
  hideImage,
}) => (

  <div className='bg-white max-w-8xl mx-auto '>
    <section className="bg-white overflow-hidden relative rounded-b-2xl">
      {/* <div className='Block-background HeroImageBlock-background u-bgColor--transparentOrange absolute right-0 top-0 bottom-20 bg-brand-50' style={{ zIndex: 0, width: '60%' }} /> */}

      <GatsbyImage
        image={getImage(heroImage)}
        alt={alt}

        loading={preloadImage}
        className="absolute top-0 left-0 w-full max-w-none h-full absolute-important "
      />      <div className='absolute  top-0 left-0 w-full h-full opacity-50 bg-gradient-to-r from-gray-900 to-transparent' />

      <div className='absolute  top-0 left-0 w-full h-full opacity-50 bg-gradient-to-r from-gray-800 to-transparent' />
      <div className='absolute  top-0 left-0 w-full h-full opacity-25 md:opacity-0 bg-gradient-to-b from-gray-800 to-transparent' />

      <div className='max-w-7xl flex flex-wrap md:flex-nowrap items-center items-stretch		mx-auto heroImageBlock-home-container z-1' >

        <motion.div className='w-full md:w-2/3  px-6 md:px-8 mb-12 md:mb-0 py-20 md:py-32 shrink-0 z-1 relative  	'

          initial={{ opacity: 0, y: 5 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: .45 }, }}

          viewport={{ once: true }}>
 
          <h4 className='font-display font-extrabold text-3xl sm:text-3xl md:text-4xl text-white'>{heroTitle}</h4>
          <p className='pt-4 text-base md:text-lg text-white md:leading-7'>
 

<div dangerouslySetInnerHTML={{ __html: description.description }} />

            {/* {description && description.description && (
              <Markdown
                className="feature-extended-image has-animation"
                children={description.description}
              />
            )} */}

          </p>


<div className='py-8'>
   
            
            <Button to='/services/' className='mt-4'>Explore Services <ArrowRight size={16} className='ml-2'/></Button>
          
          </div>

          <div>
          <HeroRatingStars theme="light" classes="justify-start text-left" centerAll={false} /></div>
        </motion.div>


      </div>
    </section>
  </div>
)

const HeroArea = ({ heroContent }) => {

  let isMultiSlide = false

  if (heroContent.length || heroContent.length > 2) {
    isMultiSlide = true
  }

  const isBuildingHTML = typeof window === 'undefined'


  if (Array.isArray(heroContent)) {
    heroContent = heroContent[0]
  }

  // return <HeroContent {...heroContent} preloadImage />

  if (Array.isArray(heroContent)) {
    return (
      <SliderContainer className={isBuildingHTML ? 'server-rendered' : null}>
        <Slider {...settings}>
          {heroContent.map((item, index) => (
            <HeroContent
              {...item}
              alt={item.heroTitle}
              key={index}
              preloadImage={index == 0}
            />
          ))}
        </Slider>
      </SliderContainer>
    )
  } else {
    return <HeroContent {...heroContent} preloadImage />
  }
}

export default HeroArea


// <span class="py-2 flex  font-semibold font-display"><svg viewBox="0 0 31 28" class="h-6 mr-3 w-6" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(2 2)"><circle cx="12" cy="12" r="12" stroke="#fff" stroke-width="4"></circle><path d="m7.7839364 10.0972528 4.5984096 4.5984096 13.5192024-13.51920239" stroke="#ffcc31" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"></path></g></svg><span class="ml-1 text-base md:text-lg text-white">AC Installation & Replacement</span></span>

// <span class="py-2 flex  font-semibold font-display"><svg viewBox="0 0 31 28" class="h-6 mr-3 w-6" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(2 2)"><circle cx="12" cy="12" r="12" stroke="#fff" stroke-width="4"></circle><path d="m7.7839364 10.0972528 4.5984096 4.5984096 13.5192024-13.51920239" stroke="#ffcc31" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"></path></g></svg><span class="ml-1 text-base md:text-lg text-white">Furnace Replacement</span></span>

// <span class="py-2 flex  font-semibold font-display"><svg viewBox="0 0 31 28" class="h-6 mr-3 w-6" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(2 2)"><circle cx="12" cy="12" r="12" stroke="#fff" stroke-width="4"></circle><path d="m7.7839364 10.0972528 4.5984096 4.5984096 13.5192024-13.51920239" stroke="#ffcc31" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"></path></g></svg><span class="ml-1 text-base md:text-lg text-white">Emergency HVAC Services</span></span>

// <span class="py-2 flex  font-semibold font-display"><svg viewBox="0 0 31 28" class="h-6 mr-3 w-6" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(2 2)"><circle cx="12" cy="12" r="12" stroke="#fff" stroke-width="4"></circle><path d="m7.7839364 10.0972528 4.5984096 4.5984096 13.5192024-13.51920239" stroke="#ffcc31" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"></path></g></svg><span class="ml-1 text-base md:text-lg text-white">Water Heater Installation & Repair
// </span></span>

// <span class="py-2 flex  font-semibold font-display"><svg viewBox="0 0 31 28" class="h-6 mr-3 w-6" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(2 2)"><circle cx="12" cy="12" r="12" stroke="#fff" stroke-width="4"></circle><path d="m7.7839364 10.0972528 4.5984096 4.5984096 13.5192024-13.51920239" stroke="#ffcc31" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"></path></g></svg><span class="ml-1 text-base md:text-lg text-white">Emergency Plumbing Solutions</span></span>